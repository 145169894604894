import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	popper: {
		width: 'auto !important', // Overrides inline width
		zIndex: theme.zIndex.raised,
	},
	listBox: {
		maxHeight: theme.size.small,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing.xxsmall,
		marginBottom: theme.spacing.xxsmall,
		padding: theme.spacing.medium,
		backgroundColor: theme.dropdown.background.color.default,
		border: `1px solid ${theme.dropdown.border.color.default}`,
		borderRadius: theme.spacing.tiny,
		boxShadow: theme.elevation.top.lifted,
		zIndex: theme.zIndex.lifted,
	},
}));
