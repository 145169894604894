import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'radioSpan' | 'hasNoFocusState'>()((theme, _, classes) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',

		height: theme.radio.size,
		width: theme.radio.size,
	},

	radioSpan: {
		width: theme.radio.size,
		position: 'relative',
		display: 'block',
		border: theme.radio.border.unselected.default,
		borderRadius: '50%',
		backgroundColor: theme.radio.unselected.background.color.default,
		'&:after': {
			content: "''",
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			borderRadius: '50%',
			height: `calc(${theme.radio.size}/2)`,
			width: `calc(${theme.radio.size}/2)`,
			display: 'none',
		},
	},

	hasNoFocusState: {},

	radioButton: {
		position: 'absolute',
		opacity: 0,
		width: '100%',
		height: '100%',
		zIndex: 1,
		cursor: 'pointer',

		// UNCHECKED
		[`&:hover ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.unselected.background.color.hover,
			border: theme.radio.border.unselected.hover,
		},

		[`&:focus-visible:not(.${classes.hasNoFocusState}) ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.unselected.background.color.focus,
			boxShadow: theme.radio.focus,
			border: theme.radio.border.unselected.focus,
		},

		[`&:active ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.unselected.background.color.pressed,
			border: theme.radio.border.unselected.pressed,
		},

		// CHECKED
		[`&:checked ~ .${classes.radioSpan}`]: {
			borderWidth: '0',
			backgroundColor: theme.radio.selected.background.color.default,

			'&:after': {
				display: 'block',
				backgroundColor: theme.radio.selected.foreground.color.default,
			},
		},

		[`&:checked:hover ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.selected.background.color.hover,
		},

		[`&:checked:focus-visible:not(.${classes.hasNoFocusState}) ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.selected.background.color.focus,
			borderWidth: '0',
		},

		[`&:checked:active ~ .${classes.radioSpan}`]: {
			backgroundColor: theme.radio.selected.background.color.pressed,
		},

		// DISABLED UNCHECKED
		'&:disabled': {
			pointerEvents: 'none',
		},

		[`&:disabled ~ .${classes.radioSpan}`]: {
			border: theme.radio.border.unselected.disabled,
			backgroundColor: theme.radio.unselected.background.color.disabled,
		},

		// DISABLED CHECKED
		[`&:disabled:checked ~ .${classes.radioSpan}`]: {
			borderWidth: '0',
			backgroundColor: theme.radio.selected.background.color.disabled,

			'&:after': {
				display: 'block',
				backgroundColor: theme.radio.selected.foreground.color.disabled,
			},
		},
	},
}));
