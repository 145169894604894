import { getUpToBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	inputField: {
		cursor: 'pointer',

		'& > *': {
			cursor: 'pointer',
		},
	},
	popper: {
		'> div': {
			[getUpToBreakpoint('sm')]: {
				padding: theme.spacing.xxsmall,
			},
		},
	},
	drawer: {
		[getUpToBreakpoint('sm')]: {
			padding: theme.spacing.tiny,
			'& .flatpickr-innerContainer': {
				display: 'block',
			},
			'& .dayContainer': {
				maxWidth: '303px',
				minWidth: '303px',
				width: '303px',
			},
		},
	},
}));
