import React, { FC, useState, useCallback, useEffect, useRef, useMemo, ChangeEvent } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Alert } from '@vakantiesnl/components/src/atoms/Alert';
import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { sumObjectValues } from '@vakantiesnl/services/src/util/mathUtils';
import { inject } from '@vakantiesnl/services/src/util/template';
import { MicroCopy, Search } from '@vakantiesnl/types';

import { useNewStyles } from './MultiRoomPartyPicker.style';
import { SingleRoomPartyPicker } from './SingleRoomPartyPicker';

export const defaultParty = [{ adults: 2, children: 0, babies: 0 }];

export type MultiRoomPartyPickerProps = {
	/** The maximum count of party compositions allowed. */
	maxCount?: number;
	/** The initial party compositions.*/
	initialParty?: Search.PartyComposition[];
	microCopy: MicroCopy;
	/**
	 * Callback function triggered when the party compositions are updated.
	 * @param party - The updated party compositions.
	 */
	onPartyUpdate?: (party: Search.PartyComposition[], updateValue?: boolean) => void;
	/**
	 * Callback function triggered when the component is closed.
	 * @param party - The final party compositions.
	 */
	onClose?: (party: Search.PartyComposition[]) => void;
	/** Determines whether the component is open or not. */
	isOpen: boolean;
	/** Custom styles for the component wrapper. */
	customWrapperStyles?: string;
	/** Toggle to hide multi room selection, purpose for single room selection for Qenner */
	hideMultiRoom?: boolean;
};

export const MultiRoomPartyPicker: FC<MultiRoomPartyPickerProps> = ({
	maxCount = 9,
	initialParty = defaultParty,
	microCopy,
	onPartyUpdate,
	onClose,
	isOpen,
	customWrapperStyles,
	hideMultiRoom = false,
}) => {
	const { isDesktop } = useBreakpoints();
	const [party, setParty] = useState<Search.PartyComposition[]>(initialParty);

	const { classes, cx } = useNewStyles();

	useEffect(() => {
		// If the party gets changed outside of this component (e.g. navigating back)
		// make sure to sync it again
		setParty(initialParty);
	}, [initialParty]);

	const [activeRoom, setActiveRoom] = useState(0);
	const partyPickerRef = useRef<HTMLDivElement>(null);

	const totalCount = useMemo(() => party.reduce((prev, curr) => prev + sumObjectValues(curr), 0), [party]);

	const handleOutsideClick = useCallback(
		(event: MouseEvent): void => {
			if (isDesktop) {
				if (partyPickerRef.current) {
					const target = event.target as HTMLElement;
					if (!partyPickerRef.current.contains(target)) {
						if (isDesktop) {
							onClose?.(party);
						} else {
							onClose?.(initialParty);
						}
					}
				}
			}
		},
		[onClose, initialParty, party, isDesktop],
	);

	const handleEsc = useCallback(
		(event: KeyboardEvent): void => {
			if (event.key === 'Escape' || event.key === 'Esc') {
				onClose?.(party);
			}
		},
		[onClose, party],
	);

	// Reset active room to 0
	useEffect(() => {
		return () => {
			!isOpen && setActiveRoom(0);
		};
	}, [isOpen]);

	// ESC button press listener
	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', handleEsc);
		}
		return (): void => window.removeEventListener('keydown', handleEsc);
	}, [handleEsc, isOpen]);

	// Outside click listener
	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return (): void => document.removeEventListener('mousedown', handleOutsideClick);
	}, [handleOutsideClick, isOpen]);

	const updateRoomParty = useCallback(
		(roomIndex: number, roomParty: Search.PartyComposition) => {
			const newParty = [...party];
			newParty.splice(roomIndex, 1, roomParty);
			setParty(newParty);
			onPartyUpdate?.(newParty);
		},
		[onPartyUpdate, setParty, party],
	);

	const handleTabChange = useCallback(
		(_event: ChangeEvent<unknown>, newValue: number) => setActiveRoom(newValue),
		[],
	);

	const getMaxCountForRoom = useCallback(
		(roomIndex: number) =>
			maxCount - party.reduce((prev, curr, index) => prev + (roomIndex === index ? 0 : sumObjectValues(curr)), 0),
		[maxCount, party],
	);

	const handleAddRoom = useCallback(() => {
		const newParty = [...party, { adults: 1, children: 0, babies: 0 }];
		setParty(newParty);
		onPartyUpdate?.(newParty);
		setActiveRoom(newParty.length - 1);
	}, [onPartyUpdate, party]);

	const handleRemoveRoom = useCallback(
		(roomIndex: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			const newParty = party.filter((_, index) => index !== roomIndex);
			setActiveRoom(0);
			setParty(newParty);
			onPartyUpdate?.(newParty);
		},
		[onPartyUpdate, party],
	);

	const handleApplyButtonClick = useCallback(() => {
		onClose?.(party);
		onPartyUpdate?.(party, true);
	}, [onClose, party, onPartyUpdate]);

	return (
		<div
			className={cx(classes.wrapper, customWrapperStyles)}
			ref={partyPickerRef}
			data-cy="multi-room-party-picker"
		>
			<div className={classes.mobileWrapper}>
				{!hideMultiRoom && (
					<div className={classes.roomContainer}>
						{party.length > 0 && (
							<Tabs value={activeRoom} onChange={handleTabChange} className={classes.tabs}>
								{party.map((_, roomIndex) => (
									<Tab
										key={roomIndex}
										value={roomIndex}
										label={
											<Button
												asText
												variant="tertiary"
												leadingIcon="room"
												className={classes.roomButton}
												active={activeRoom === roomIndex}
												size="small"
											>
												<Typography variant="labelSm" as="span" className={classes.roomNumber}>
													{roomIndex + 1}
												</Typography>
											</Button>
										}
										id={`simple-tab-${roomIndex}`}
										aria-controls={`simple-tabpanel-${roomIndex}`}
										className={classes.newTab}
										data-cy={`room-tab-${roomIndex + 1}`}
									/>
								))}
							</Tabs>
						)}
						{party.length < 4 && totalCount < maxCount && (
							<Button
								onClick={handleAddRoom}
								data-cy="add-new-room-button"
								leadingIcon="plus"
								variant="tertiary"
								size="small"
								className={classes.addRoomButton}
							>
								{party.length === 1 && (
									<Typography variant="labelSm" as="span">
										{microCopy['filters.pax.addRoom']}
									</Typography>
								)}
							</Button>
						)}
					</div>
				)}
				<div className={classes.pickersContainer}>
					{totalCount === maxCount && (
						<div data-cy="party-full-warning">
							<Alert
								text={inject(microCopy['filters.partyPicker.maxInfo'], {
									maxCount: maxCount.toString(),
								})}
								variant="warning"
								className={classes.warning}
							/>
						</div>
					)}
					{party.length > 0 && (
						<Typography
							variant="headingSm"
							as="h3"
							className={cx(classes.inThisRoom, { [classes.inThisRoomNoMultiRoom]: hideMultiRoom })}
						>
							{hideMultiRoom
								? microCopy['common.travel_party']
								: microCopy['filters.partyPicker.inThisRoom']}
						</Typography>
					)}
					{party.map(
						(party, roomIndex) =>
							activeRoom === roomIndex && (
								<div
									key={roomIndex}
									role="tabpanel"
									id={`simple-tabpanel-${roomIndex}`}
									aria-labelledby={`simple-tab-${roomIndex}`}
								>
									<SingleRoomPartyPicker
										roomIndex={roomIndex}
										party={party}
										maxCount={getMaxCountForRoom(roomIndex)}
										microCopy={microCopy}
										onRoomPartyUpdate={updateRoomParty}
									/>
								</div>
							),
					)}
					{party.length > 1 && (
						<Button
							variant="secondary"
							onClick={handleRemoveRoom(activeRoom)}
							size="small"
							leadingIcon="minus"
							className={classes.removeButton}
							data-cy="room-tab-remove-button"
						>
							{microCopy['filters.pax.removeRoom']}
						</Button>
					)}
				</div>
			</div>
			{!isDesktop && (
				<Button className={classes.acceptButton} variant="primary" onClick={handleApplyButtonClick}>
					{microCopy['common.apply']}
				</Button>
			)}
		</div>
	);
};
