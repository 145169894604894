import { optionClasses } from '@mui/base/Option';
import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'optionLabel'>()((theme, _, classes) => ({
	popper: {
		zIndex: theme.zIndex.raised,
	},
	listBox: {
		maxHeight: theme.size.small,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.tiny,
		marginTop: theme.spacing.xxsmall,
		marginBottom: theme.spacing.xxsmall,
		padding: `${theme.spacing.xxsmall} 0`,

		[getFromBreakpoint('lg')]: {
			backgroundColor: theme.dropdown.background.color.default,
			border: `1px solid ${theme.dropdown.border.color.default}`,
			borderRadius: theme.spacing.tiny,
			boxShadow: theme.elevation.top.lifted,
			zIndex: theme.zIndex.lifted,
		},
	},
	inputComponent: {
		cursor: 'pointer',

		'& > *': {
			cursor: 'pointer',
		},
	},
	defaultListItem: {
		padding: `${theme.spacing.tiny} ${theme.spacing.small}`,
	},
	textFieldWrapper: {
		position: 'relative',
	},
	clickElement: {
		cursor: 'pointer',
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
	},
	listItem: {
		listStyle: 'none',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		color: theme.dropdown.listItem.color.default,

		input: {
			zIndex: -1,
		},

		/** Some hover styling for the radio/checkbox options */
		['&:hover']: {
			backgroundColor: theme.dropdown.listItem.background.color.hover,
			[`.${classes.optionLabel}`]: {
				color: theme.dropdown.listItem.color.hover,
			},

			['div > span']: {
				backgroundColor: theme.radio.unselected.background.color.hover,
				border: theme.radio.border.unselected.hover,
			},
			['input:checked ~ span']: {
				backgroundColor: theme.checkbox.selected.background.color.hover,
			},
		},

		[`.${optionClasses.highlighted}&&`]: {
			backgroundColor: theme.dropdown.listItem.background.color.hover,
		},

		[`.${optionClasses.selected}&&`]: {
			color: theme.dropdown.listItem.color.selected,
		},
	},
	listItemSmall: {
		gap: theme.spacing.xsmall,
		minHeight: theme.spacing.large,
		padding: 0,
		font: theme.typography.bodyMd,
	},
	listItemMedium: {
		gap: theme.spacing.xsmall,
		minHeight: theme.spacing.large,
		padding: 0,
		[getFromBreakpoint('lg')]: {
			padding: `${theme.spacing.tiny} ${theme.spacing.small}`,
		},
		font: theme.typography.bodyMd,
	},
	li: {
		marginBottom: theme.spacing.xxsmall,
	},
	optionLabel: {},
	paxListBox: {
		paddingTop: 0,
		paddingBottom: 0,
		minWidth: '300px',
	},
	link: {
		textDecoration: 'none',
	},
	liTop: {
		paddingLeft: theme.spacing.small,
		paddingBottom: theme.spacing.xxsmall,
		paddingRight: theme.spacing.xlarge,
	},
	liBottom: {
		paddingLeft: theme.spacing.small,
		paddingTop: theme.spacing.xxsmall,
	},
	multiRoomPartyPickerWrapper: {
		padding: theme.spacing.small,
	},
	drawerContentsWrapper: {
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-between',
		display: 'flex',
	},
	confimButton: {
		width: '100%',
		marginTop: 'auto',
	},
}));
