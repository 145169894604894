import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<{ count: number }>()((theme, { count }) => ({
	text: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: theme.spacing.micro,
	},
	warning: {
		color: theme.palette.blazeOrange[100],
	},
	numberPicker: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	numberPickerInfo: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	numberPickerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	count: {
		color: count === 0 ? theme.palette.neutral[40] : theme.body.foreground.color.default,
		padding: `0 ${theme.spacing.xsmall}`,
		fontVariantNumeric: 'tabular-nums',
	},
	title: {
		color: theme.body.foreground.color.default,
	},
	description: {
		margin: 0,
		color: theme.input.message.color.default,
	},
}));
