import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	numberPicker: {
		paddingBottom: 0,
	},
	divider: {
		margin: `${theme.spacing.small} 0`,
	},
}));
