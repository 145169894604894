import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useNewStyles = makeStyles<void>()((theme) => ({
	tab: {
		height: theme.spacing.large,
	},
	tabActive: {
		backgroundColor: 'white',
		borderBottomStyle: 'none',
		borderLeftStyle: 'solid',
		borderRightStyle: 'solid',

		'&:first-of-type': {
			borderLeftStyle: 'none',
		},

		'&:last-of-type': {
			borderRightStyle: 'none',
		},

		'& span': {
			color: theme.button.primary.background.color.default,
			fontWeight: 'bold',
		},
	},
	removeIcon: {
		opacity: 0.5,

		'&:hover': {
			opacity: 1,
		},
	},

	warning: {
		marginTop: theme.spacing.small,
	},
	icon: {
		height: theme.spacing.xlarge,
		width: theme.spacing.xlarge,
	},
	tabs: {
		display: 'flex',
		'& .MuiTabs-indicator': {
			display: 'none',
		},
		'& .MuiTabs-flexContainer': {
			gap: theme.spacing.tiny,
		},
		maxWidth: '100%',
	},
	newTab: {
		padding: 0,
		minWidth: 'unset !important',
		boxSizing: 'border-box',
	},
	removeButton: {
		alignSelf: 'flex-start',
		marginTop: theme.spacing.small,
	},
	mobileWrapper: {
		height: '100%',
	},
	roomContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	pickersContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
	},
	inThisRoom: {
		padding: `${theme.spacing.small} 0`,
	},
	inThisRoomNoMultiRoom: {
		paddingTop: 0,
	},
	roomButton: {
		borderRadius: theme.borderRadius.rounded,
		width: '100%',
	},
	roomNumber: {
		fontVariantNumeric: 'tabular-nums',
	},
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		cursor: 'default',
		justifyContent: 'space-between',
	},
	addRoomButton: {
		marginLeft: theme.spacing.tiny,
	},
	acceptButton: {
		marginTop: theme.spacing.small,
	},
}));
